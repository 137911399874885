import { ActivityTypeEnum } from '@falla/types/src/enum';
import { ActivitiesReward, GetRankingParams, Regions, TemplateConfigRank } from '@falla/types/src/types';

import { leftPade } from './string-util';
import { isNumber } from './type-util';

/**
 * format-util
 *
 * @author fukui
 */
export enum ScoreUnit {
  default = '',
  k = 'K',
  m = 'M',
}

export enum ScoreUnitAr {
  default = '',
  k = 'ألف',
  m = 'مليون',
}

export const numberToScore = function (num: number, lang?: string): string | number {
  let score: number | string = parseInt(String(num || 0));
  if (score >= 1000000) {
    score = `${parseInt(String(score / 100000)) / 10}${isArLang(lang) ? ScoreUnitAr.m : ScoreUnit.m}`;
  } else if (score > 1000) {
    score = `${parseInt(String(score / 100)) / 10}${isArLang(lang) ? ScoreUnitAr.k : ScoreUnit.k}`;
  }
  return score;
};

// 保留两位小数
export const scoreKeepTwoDecimals = function (num: number, lang?: string): string | number {
  let score: number | string = parseInt(String(num || 0));
  if (score >= 1000000) {
    score = `${parseInt(String(score / 10000)) / 100}${isArLang(lang) ? ScoreUnitAr.m : ScoreUnit.m}`;
  } else if (score > 1000) {
    score = `${parseInt(String(score / 10)) / 100}${isArLang(lang) ? ScoreUnitAr.k : ScoreUnit.k}`;
  }
  return score;
};

export interface ScoreMap {
  value: number;
  unit?: ScoreUnit | ScoreUnitAr;
}

export const isArLang = (lang?: string) => {
  return (lang || '').includes('ar');
};

export const numberToScoreMap = function (num: number, lang?: string): ScoreMap {
  const scoreMap: ScoreMap = {
    value: num,
    unit: ScoreUnit.default,
  };
  if (num >= 1000000) {
    scoreMap.value = parseInt(String(num / 100000)) / 10;
    scoreMap.unit = isArLang(lang) ? ScoreUnitAr.m : ScoreUnit.m;
  } else if (num > 1000) {
    scoreMap.value = parseInt(String(num / 100)) / 10;
    scoreMap.unit = isArLang(lang) ? ScoreUnitAr.k : ScoreUnit.k;
  }
  return scoreMap;
};

export const replaceVochatToFalla = (text: string): string => {
  return text
    .replace(/VoChat'e/g, "Falla'ya")
    .replace(/VoChat/g, 'Falla')
    .replace(/Vochat/g, 'Falla')
    .replace(/vochat/g, 'falla')
    .replace(/الفوشات/g, 'فله')
    .replace(/فوشات/g, 'فله');
};

export const replaceFallaToVochat = (text: string): string => {
  return text
    .replace(/Falla'ya/g, "VoChat'e")
    .replace(/Falla/g, 'Vochat')
    .replace(/falla/g, 'vochat')
    .replace(/فله/g, 'الفوشات')
    .replace(/فله/g, 'فوشات');
};

export const replaceJoymiToJeeMe = (text: string): string => {
  if (!text) return '';

  return text
    .replace(/web.joymiweb.com/g, 'web.jeeme.live')
    .replace(/www.joymi.live/g, 'www.jeeme.live')
    .replace(/game.joymiweb.com/g, 'game.jeemeweb.com')
    .replace(/JoyMi'ya/g, 'JeeMe')
    .replace(/JoyMi'nın/g, 'JeeMe')
    .replace(/JoyMi'yı/g, 'JeeMe')
    .replace(/JoyMi/g, 'JeeMe')
    .replace(/joyMi/g, 'jeeMe')
    .replace(/joymi/g, 'jeeMe')
    .replace(/فله/g, 'JeeMe');
};

export const parseMs = (
  milliseconds: number,
): {
  days: string;
  hours: string;
  minutes: string;
  seconds: string;
  milliseconds: string;
} => {
  return {
    days: leftPade(Math.floor(milliseconds / 86400000), 2, '0'),
    hours: leftPade(Math.floor(milliseconds / 3600000) % 24, 2, '0'),
    minutes: leftPade(Math.floor(milliseconds / 60000) % 60, 2, '0'),
    seconds: leftPade(Math.floor(milliseconds / 1000) % 60, 2, '0'),
    milliseconds: leftPade(Math.floor(milliseconds) % 1000, 2, '0'),
  };
};

export const pxToRem = (px: number, rootValue = 75) => {
  return px / rootValue;
};

export const px2rem = (px: number | string | undefined, rootValue = 75): string => {
  if (isNumber(px)) return `${pxToRem(Number(px), rootValue)}rem`;
  if (!String(px).includes('px')) return px as string;
  const val = Number(String(px).replace('px', ''));
  return `${pxToRem(val, rootValue)}rem`;
};

export const getCssBackgroundImage = (backgroundImage: string) => {
  return `url(${backgroundImage})`;
};

export const valCut = (val: number | string = 0, len = 3): string => {
  val = String(val);
  const valLen = val.length;
  if (valLen < len) return val;

  const decimalIndex = val.indexOf('.');
  const decimalPart = val.slice(decimalIndex + 1);

  // 是否有小数
  const isDecimal = decimalIndex !== -1;
  let res = isDecimal ? `${decimalPart}` : '';
  let i = isDecimal ? decimalIndex : valLen - 1;
  let count = 1;

  while (i >= 0) {
    if (count % len === 0 && i !== 0) {
      // TODO TR 分割用（.）  其他分割用（,）
      res = `.${val.charAt(i)}${res}`;
    } else {
      res = `${val.charAt(i)}${res}`;
    }
    if (val.charAt(i) !== '.') {
      count++;
    }
    i--;
  }

  return res;
};

// 任何奖励格式  转成 ActivitiesReward 格式
interface RewardRowListItemFormatOptions {
  list: Array<{
    cover: string;
    name: string;
  }>;
}

export const rewardRowListItemFormat = (option: RewardRowListItemFormatOptions): ActivitiesReward => {
  const giftList =
    option?.list?.map((v, i) => {
      return {
        ...v,
        id: i,
        name: '1',
        expireType: 8,
        cover: v.cover,
        sort: 1,
        nameI18n: {
          ar: v.name,
          en: v.name,
          tr: v.name,
          zh: v.name,
          es: v.name,
          pt: v.name,
          bn: v.name,
          hi: v.name,
          ur: v.name,
        },
      };
    }) || [];
  return {
    id: 1,
    name: '1',
    sort: 1,
    displayStyle: 1,
    nameI18n: {
      ar: '',
      en: '',
      tr: '',
      zh: '',
      es: '',
      pt: '',
      bn: '',
      hi: '',
      ur: '',
    },
    activityGiftConfigGroupGiftList: giftList,
  };
};

export interface RankScoreFormatOptions {
  activityType?: GetRankingParams['activityType'];
  val: number;
  isMine?: boolean;
  language?: string;
  rankHidden?: TemplateConfigRank;
}

export const rankScoreFormat = (opt?: RankScoreFormatOptions) => {
  const { activityType, val, language, rankHidden, isMine } = opt;

  if (activityType === ActivityTypeEnum.rankUserRecharge) {
    if (!isMine && rankHidden?.userRechargeHiddenScore) {
      return '******';
    }

    // 保留两位小数
    if (val < 100000) {
      return val / 100;
    }

    return numberToScore(val / 100, opt.language);
  }

  return numberToScore(val, language);
};

// ratio 0 ~ 1 百分比   vh 兼容方案。
export const getWindowHeightByRatio = (ratio = 1) => {
  const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

  return windowHeight * ratio;
};

// banner 图兜底展示
// op至少上传一个分区的图片，  其它分区兜底展示 。
export const getBannerByRegion = (
  banner?: {
    [key in Regions]?: string;
  },
  region?: Regions,
): string => {
  let img = banner?.[region || Regions.TR];

  if (img) return img;

  // 优先使用土语兜底
  if (banner?.[Regions.TR]) return banner?.[Regions.TR];

  const key = (Object.keys(banner || {}).find((k) => k) || '') as Regions;

  img = banner?.[key];

  return img || '';
};

export const PX2REM_KEYS = [
  'fontSize',
  'lineHeight',
  'width',
  'height',
  'maxWidth',
  'minWidth',
  'maxHeight',
  'minHeight',
  'marginTop',
  'marginLeft',
  'marginBottom',
  'marginRight',
  'left',
  'right',
  'top',
  'bottom',
  'paddingLeft',
  'paddingRight',
  'paddingTop',
  'paddingBottom',
  'borderRadius',
  'borderBottomLeftRadius',
  'borderBottomRightRadius',
  'borderTopLeftRadius',
  'borderTopRightRadius',
  'backgroundPositionY',
  'backgroundPositionX',
  'borderWidth',
  'borderLeft',
  'borderRight',
  'borderTop',
  'borderBottom',
];

export const cssStyleFormat = (style?: React.CSSProperties) => {
  Object.keys(style).forEach((k) => {
    const key = k as keyof React.CSSProperties;
    const val = style[key as keyof React.CSSProperties];
    if (typeof val === 'number' && PX2REM_KEYS.includes(key)) {
      // @ts-ignore
      style[key] = px2rem(val);
    }

    return val;
  });
  return style;
};
